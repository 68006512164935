/**
 * /* ./src/index.css
 *
 * @format
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  width: 100%;
  height: 100%;
}
